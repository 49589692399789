export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'isFinished',
                    label: 'labels.unfinished',
                    value: '0',
                    inputType: 'checkbox',
                    default: true,
                },
            ]
        },
    },
}
