export default {
    computed: {
        headers() {
            return [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: 'description',
                    text: this.$t('labels.description'),
                    type: 'string',
                },
                {
                    value: 'dueDate',
                    text: this.$t('labels.due_date'),
                },
                {
                    sortable: false,
                    value: 'related',
                    text: this.$t('labels.related'),
                },
                {
                    value: '_rowActions',
                    sortable: false,
                    text: this.$t('labels.finish'),
                    class: 'row-actions-header',
                    width: '100px',
                },
            ]
        },
    },
}
