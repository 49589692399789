var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"visible-actions":""},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"todo-description"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemDue(item))+" ")]}},{key:"item.related",fn:function(ref){
var item = ref.item;
return [_c('ul',{staticClass:"py-1"},[(item.user)?_c('li',{staticClass:"list-spacing"},[_c('router-link',{attrs:{"to":{
                                name: _vm.routeType.USER_EDIT,
                                params: { userId: item.user.id },
                            }}},[_vm._v(" "+_vm._s(_vm.$t('labels.user'))+": "+_vm._s(_vm.getName(_vm.SACT.USER, item.user.id))+" ")])],1):_vm._e(),(item.ordering)?_c('li',{staticClass:"list-spacing"},[_c('router-link',{attrs:{"to":{
                                name: _vm.routeType.ORDER_EDIT,
                                params: { id: item.ordering.id },
                            }}},[_vm._v(" "+_vm._s(_vm.$t('labels.order'))+": "+_vm._s(_vm.getName(_vm.SACT.ORDER, item.ordering.id))+" ")])],1):_vm._e(),(item.company)?_c('li',{staticClass:"list-spacing"},[_c('router-link',{attrs:{"to":{
                                name: _vm.routeType.COMPANY_EDIT,
                                params: { companyId: item.company.id },
                            }}},[_vm._v(" "+_vm._s(_vm.$t('labels.company'))+": "+_vm._s(_vm.getName(_vm.SACT.COMPANY, item.company.id))+" ")])],1):_vm._e(),(item.restaurant)?_c('li',{staticClass:"list-spacing"},[_c('router-link',{attrs:{"to":{
                                name: _vm.routeType.RESTAURANT_EDIT,
                                params: { id: item.restaurant.id },
                            }}},[_vm._v(" "+_vm._s(_vm.$t('labels.restaurant'))+": "+_vm._s(_vm.getName(_vm.SACT.RESTAURANT, item.restaurant.id))+" ")])],1):_vm._e()])]}},{key:"rowActions",fn:function(ref){
                            var item = ref.item;
return [(!item.isFinished)?_c('edit-button',{attrs:{"icon":"check_box","color":"success","tooltip":_vm.$t('actions.finish')},on:{"execute":function($event){_vm.finishItem = item.id}}}):_vm._e()]}}],null,true)}),_c('confirm-modal',{attrs:{"value":!!_vm.finishItem,"text":_vm.$t('texts.confirm_todo_finish', { todo: _vm.finishItem }),"progress":_vm.finishProgress},on:{"onApprove":_vm.doFinish,"onCancel":function($event){_vm.finishItem = null}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }