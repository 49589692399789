<template>
    <div>
        <page-title>{{ $t('menu.todos') }}</page-title>
        <todo-table ref="table">
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.TODO_CREATE"
                    tabbed
                ></create-button>
            </template>
        </todo-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import CreateButton from '@/components/form/Actions/CreateButton'
import TodoTable from '@/components/pages/todo/Table'

export default {
    components: {
        PageTitle,
        CreateButton,
        TodoTable,
    },
    data() {
        return {
            routeType,
        }
    },
}
</script>
