<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        :fixed-filters="fixedFilters"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>

        <template v-slot:filters="{ busy }">
            <table-filters
                v-model="filters"
                :fields="headers"
                :busy="busy"
                :list-filters="listFilters"
                @input="updateFilters"
            ></table-filters>
        </template>

        <template slot-scope="{ items }">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                visible-actions
            >
                <!-- id column -->
                <template v-slot:[`item.id`]="{ item }">
                    <router-link :to="getEditLink(item)">
                        {{ item.id }}
                    </router-link>
                </template>

                <!-- description column -->
                <template v-slot:[`item.description`]="{ item }">
                    <div class="todo-description">
                        {{ item.description }}
                    </div>
                </template>

                <!-- dueDate column -->
                <template v-slot:[`item.dueDate`]="{ item }">
                    {{ getItemDue(item) }}
                </template>

                <!-- related column -->
                <template v-slot:[`item.related`]="{ item }">
                    <ul class="py-1">
                        <li v-if="item.user" class="list-spacing">
                            <router-link
                                :to="{
                                    name: routeType.USER_EDIT,
                                    params: { userId: item.user.id },
                                }"
                            >
                                {{ $t('labels.user') }}:
                                {{ getName(SACT.USER, item.user.id) }}
                            </router-link>
                        </li>

                        <li v-if="item.ordering" class="list-spacing">
                            <router-link
                                :to="{
                                    name: routeType.ORDER_EDIT,
                                    params: { id: item.ordering.id },
                                }"
                            >
                                {{ $t('labels.order') }}:
                                {{ getName(SACT.ORDER, item.ordering.id) }}
                            </router-link>
                        </li>

                        <li v-if="item.company" class="list-spacing">
                            <router-link
                                :to="{
                                    name: routeType.COMPANY_EDIT,
                                    params: { companyId: item.company.id },
                                }"
                            >
                                {{ $t('labels.company') }}:
                                {{ getName(SACT.COMPANY, item.company.id) }}
                            </router-link>
                        </li>

                        <li v-if="item.restaurant" class="list-spacing">
                            <router-link
                                :to="{
                                    name: routeType.RESTAURANT_EDIT,
                                    params: { id: item.restaurant.id },
                                }"
                            >
                                {{ $t('labels.restaurant') }}:
                                {{
                                    getName(SACT.RESTAURANT, item.restaurant.id)
                                }}
                            </router-link>
                        </li>
                    </ul>
                </template>

                <!-- finish column -->
                <template v-slot:rowActions="{ item }">
                    <edit-button
                        v-if="!item.isFinished"
                        icon="check_box"
                        color="success"
                        :tooltip="$t('actions.finish')"
                        @execute="finishItem = item.id"
                    ></edit-button>
                </template>
            </d-table>
            <confirm-modal
                :value="!!finishItem"
                :text="$t('texts.confirm_todo_finish', { todo: finishItem })"
                :progress="finishProgress"
                @onApprove="doFinish"
                @onCancel="finishItem = null"
            ></confirm-modal>
        </template>
    </data-iterator>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import selectapiType from '@/store/type/selectapiType'

import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from './mixins/Headers'
import ListFilters from './mixins/ListFilters'

import EditButton from '@/components/table/buttons/EditButton'
import ConfirmModal from '@/components/confirm/ConfirmModal'

import { DATE_TIME_FORMAT } from '@/const/DateTimeFormats'
import TodoApiClient from '@/api/RestApi/TodoApiClient'
import Notification from '@/services/Notification/Notification'

const SACT = selectapiType.clientTypes

export default {
    components: { EditButton, ConfirmModal },

    mixins: [DataIteratorMixin, DataTableMixin, Headers, ListFilters],

    data() {
        const after = this.$date().subtract(1, 'hour').format(DATE_TIME_FORMAT)

        return {
            api: RestApiType.TODOS,
            routeType,
            SACT,
            filters: {
                order: {
                    dueDate: 'desc',
                },
            },
            fixedFilters: {
                dueDate: {
                    after,
                },
            },
            [SACT.USER]: [],
            [SACT.ORDER]: [],
            [SACT.COMPANY]: [],
            [SACT.RESTAURANT]: [],
            finishItem: null,
            finishProgress: false,
            refreshTimer: null,
        }
    },

    mounted() {
        const load = (type) => {
            this.$store
                .dispatch(
                    selectapiType.getActionName(
                        type,
                        selectapiType.actions.LOAD
                    )
                )
                .then((data) => {
                    this[type] = data
                })
        }

        load(SACT.USER)
        load(SACT.ORDER)
        load(SACT.COMPANY)
        load(SACT.RESTAURANT)

        this.refreshTimer = setInterval(() => {
            this.$refs.iterator.updateItems(true)
        }, 5 * 60 * 1000)
    },

    beforeDestroy() {
        clearInterval(this.refreshTimer)
    },

    methods: {
        getEditLink(item) {
            return {
                name: routeType.TODO_EDIT,
                params: { id: item.id },
            }
        },
        getItemDue(item) {
            return this.$date(item.dueDate).format(DATE_TIME_FORMAT)
        },
        getName(apiType, id) {
            return this[apiType].find((el) => el.id === id)?.label ?? `#${id}`
        },
        doFinish() {
            this.finishProgress = true
            TodoApiClient.finish(this.finishItem)
                .then(() => {
                    Notification.success(
                        this.$t('notifications.success'),
                        this.$t('notifications.todo.finished', {
                            todo: this.finishItem,
                        })
                    )
                    this.finishItem = null
                    this.$refs.iterator.updateItems(true)
                })
                .finally(() => {
                    this.finishProgress = false
                })
        },
    },
}
</script>

<style>
.todo-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list-spacing {
    margin-bottom: 6px;
}
</style>
